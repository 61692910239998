import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { AppAbstract } from 'src/app/app.abstract';
import { FormaPagamentoEnum } from 'src/app/enuns/forma-pagamento.enum';
import { DadosInterface } from 'src/app/models/dados.interface';
import { BancoEnum } from '../../../enuns/banco.enum';
import { ImagemComponent } from '../../../modals/imagem/imagem.component';
import { AtencaoComponent } from 'src/app/modals/atencao/atencao.component';
import { MensagemComponent } from 'src/app/modals/mensagem/mensagem.component';

@Component({
  selector: 'ftc-debito-conta',
  templateUrl: './debito-conta.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    NgFor,
    NgIf,
    NgxMaskModule,
    ImagemComponent,
    MensagemComponent
  ],
})
export class DebitoContaComponent extends AppAbstract implements OnInit {
  @Output() debitOutput = new EventEmitter();

  dados: DadosInterface = {} as DadosInterface;

  BancoEnum: typeof BancoEnum = BancoEnum;

  DEFAULT_CODE_OPERATION: string = '3701';

  formDebito!: FormGroup;

  modalOpen = false;


  openModalImage: boolean = false;
  submetido: boolean = false;

  bankSelected: string = '';

  listaBancos: any[] = [];



  listaCodigosBancarios: any[] = [];
  listaDiasNumeral: any[] = [];

  ngOnInit(): void {
    
    this.dados = this._localStorageService.descriptografarDados();
    if(this.dados.pagamento?.banco?.bancoId == "104") {
      this.bankSelected = '104';
    } 
    this.criarFormulario();

  }

  private criarFormulario(): void {
    this.formDebito = this._formBuilder.group({
      banco: [this.dados.pagamento?.banco?.bancoId || '', Validators.required],
      codigo: [this.dados.pagamento?.banco?.codigoOperacao || '', Validators.required],
      dia: [this.dados.pagamento?.diaPagamento || '', Validators.required],
      agencia: [this.dados.pagamento?.banco?.agencia || '', Validators.required],
      conta: [this.dados.pagamento?.banco?.conta || '', [Validators.required]],
      digito: [this.dados.pagamento?.banco?.digitoConta || '', Validators.required],
      codeEmpty: [false, Validators.required],
    });

    this.getListas();
  }

  private async getListas(): Promise<void> {

    await this._apiService
      .get(this._urlService.listaBancos)
      .subscribe((data: any) => (this.listaBancos = data.bancos));


    await this._apiService
      .get(this._urlService.listaCodigosBancarios)
      .subscribe((data: any) => (this.listaCodigosBancarios = data.codigos_bancarios));

    await this._apiService
      .get(this._urlService.listaDiasNumeral)
      .subscribe((data: any) => (this.listaDiasNumeral = data.diasPagamento));
  }


  revalidateForm() {

    for (let key in this.formDebito.controls) {
      this.f[key].updateValueAndValidity();
    }

  }

  changeCode(e: any) {

    if (this.formDebito.controls['codigo'].value.length == 3) {
      this.formDebito.controls['conta'].enable();
      // old account
      let control = this.formDebito.controls['conta'];
      control.setErrors(null);
      control.setValidators([Validators.required, Validators.maxLength(8)])
      this.revalidateForm();
      console.log('atualizado forms antiga conta')

    } else {
      // new account
      if(this.formDebito.controls['codigo'].value.length > 0 && this.formDebito.controls['codigo'].value != "") {
        this.formDebito.controls['conta'].enable();
      }
      let control = this.formDebito.controls['conta'];
      control.setErrors(null);
      control.setValidators([Validators.minLength(9), Validators.maxLength(12), Validators.required])
      this.revalidateForm();

      console.log('atualizado forms nova conta')

    }


    console.log(this.formDebito)
  }

  onChange(radioInput: any) {

    let checked = !this.formDebito.value.codeEmpty;
    
    if (checked) {

      // enable account option
      this.formDebito.controls['conta'].enable();

      let control = this.formDebito.controls['codigo'];
      control.setValidators(null);
      control.setErrors(null);
      this.revalidateForm();
      this.formDebito.controls['codeEmpty'].setValue(!this.formDebito.value.codeEmpty);

      let control3 = this.formDebito.controls['conta'];
      control3.setErrors(null);
      control3.setValidators([Validators.minLength(9), Validators.maxLength(12), Validators.required])
      console.log(this.formDebito)
      this.revalidateForm();
    } else {
      let control = this.formDebito.controls['codigo'];
      control.setErrors(null);
      control.setValidators(Validators.required);
      this.formDebito.controls['codeEmpty'].setValue(!this.formDebito.value.codeEmpty);
      this.revalidateForm();


    }

  }

  protected togglePagamento(value: FormaPagamentoEnum): void {
    this._pagamentoService.definirPagamento(value);
  }

  protected selecionarBanco(): void {

    if(this.formDebito.value.banco == "104") {
      this.formDebito.controls['conta'].disable();
    } 

    if(this.formDebito.value.banco != "104") {
      this.formDebito.controls['conta'].enable();
      this.formDebito.controls['codeEmpty'].setValidators(null);
      this.formDebito.controls['codigo'].setValidators(null);
      this.formDebito.controls['conta'].setValidators(Validators.required);
      this.revalidateForm();
    }

    this.bankSelected = this.formDebito.value.banco;
  }

  protected comoUsar(): void {
    this.openModalImage = true;
  }

  protected fecharModal(): void {
    this.openModalImage = false;
  }

  closeModal(): void {
    this.modalOpen = false;
  }

  protected continuar(): void {
    console.log(this.formDebito);
    this.submetido = true;
    if (this.formDebito.valid) {
      this.criptografarDebito();
      this._logService.postLog();
    }
  }

  zeroPad(num: any, numZeros: any) {
    var n = Math.abs(num);
    var zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
    var zeroString = Math.pow(10, zeros).toString().substr(1);
    if (num < 0) {
      zeroString = '-' + zeroString;
    }

    return zeroString + n;
  }

 

  private criptografarDebito(): void {


    let finalAccount;
    let finalCode;
    
    if (this.formDebito.value.codigo != undefined && this.formDebito.value.codigo.length == 3) {
      finalAccount = this.formDebito.value.conta
    } else if (this.formDebito.value.codigo != undefined && this.formDebito.value.codigo) {
      finalAccount = this.zeroPad(this.formDebito.value.conta, 12);
    }

    if (this.formDebito.value.codeEmpty) {
      finalAccount = this.zeroPad(this.formDebito.value.conta, 12);
      this.formDebito.value.codigo = this.DEFAULT_CODE_OPERATION;
    }

    this.dados.pagamento = {
      ...this.dados.pagamento,
      banco: {
        bancoNome: this._debitoService.getBancoNome(this.formDebito.value.banco),
        bancoId: this.formDebito.value.banco,
        codigoOperacao: this.formDebito.value.codigo ?? '',
        agencia: this.formDebito.value.agencia,
        conta: `${this.formDebito.value.conta}`,
        digitoConta: this.formDebito.value.digito.toLowerCase(),
        finalAccount,
      },
      diaPagamento: this.formDebito.value.dia,
    };

    if(this.dados.pagamento.banco?.bancoId == "104") {
      finalAccount = `${this.formDebito.value.codigo}${this.dados.pagamento.banco.conta}`
    }
    
    this._localStorageService.criptografarDados(this.dados);
    this.debitOutput.emit();
  }

  get f(): { [key: string]: any } {
    return this.formDebito.controls;
  }
}