<form [formGroup]="formDebito" (ngSubmit)="continuar()">
  <div class="mb-4">
    <label>Banco</label>
    <select name="banco" formControlName="banco" (change)="selecionarBanco()"
      [ngClass]="{ 'form--error': submetido && f['banco'].errors }">
      <option value="">Selecione o banco</option>
      <option *ngFor="let item of listaBancos" [value]="item.value">{{item.text}}</option>
    </select>
    <div class="form__error" *ngIf="submetido && f['banco'].errors">
      <span>Campo obrigatório</span>
    </div>
  </div>

  <div *ngIf="bankSelected == BancoEnum.CAIXA_ECONOMICA_FEDERAL">
    <div class="mb-4">
      <label>Código de operação</label>
      <select (change)="changeCode($event)" name="codigo" formControlName="codigo">
        <option value="">Selecione o código</option>
        <option *ngFor="let item of listaCodigosBancarios" [value]="item.text">{{item.text}}</option>
      </select>
      <div class="form__error" *ngIf="submetido && f['codigo'].errors">
        <span>Campo obrigatório</span>
      </div>
    </div>

    <div class="mb-6">
      <a (click)="comoUsar()" class="txt-info cursor-pointer">
        Onde encontrar o código de operação?
      </a>
    </div>

    <div class="box-radio">
      <input (click)="onChange(radioInput)" [value]="true" id="radio" #radioInput formControlName="codeEmpty" class="radio custom-radio" type="radio">
      <label>Não tenho o código da operação</label>
    </div>
  </div>

 

  <div class="flex gap-x-5">
    <div class="mb-4 w-1/2">
      <label>Dia do pagamento</label>
      <select name="dia" formControlName="dia" [ngClass]="{ 'form--error': submetido && f['dia'].errors }">
        <option value="">Selecione</option>
        <option *ngFor="let item of listaDiasNumeral" [value]="item.text">{{item.text}}</option>
      </select>
      <div class="form__error" *ngIf="submetido && f['dia'].errors">
        <span>Campo obrigatório</span>
      </div>
    </div>

    <div class="mb-4 w-1/2">
      <label>Agência</label>
      <input type="text" placeholder="Digite sua agência" name="agencia" formControlName="agencia"
        [ngClass]="{ 'form--error': submetido && f['agencia'].errors }">
      <div class="form__error" *ngIf="submetido && f['agencia'].errors">
        <span>Campo obrigatório</span>
      </div>
    </div>
  </div>

  <div class="flex gap-x-5">
    <div class="mb-4 w-3/4">
      <label>Conta</label>
      <input type="text" placeholder="Digite sua conta" name="conta" formControlName="conta"
        [ngClass]="{ 'form--error': submetido && f['conta'].errors }">
      <div class="form__error" *ngIf="submetido && f['conta'].errors">
        <span *ngIf="f['conta'].hasError('minlength')">Sua conta precisa ter {{f['conta'].errors.minlength.requiredLength}} dígitos</span>
        <span *ngIf="f['conta'].hasError('required') && !f['conta'].hasError('minlength')">Campo obrigatório</span>
        <span *ngIf="f['conta'].hasError('maxlength') && !f['conta'].hasError('minlength') && !f['conta'].hasError('required')">Sua conta pode ter até {{f['conta'].errors.maxlength.requiredLength}} dígitos</span>

      </div>
    </div>

    <div class="mb-4 w-1/4">
      <label>Digito</label>
      <input type="text" placeholder="DV" name="digito" formControlName="digito" mask="A"
        [ngClass]="{ 'form--error': submetido && f['digito'].errors }">
      <div class="form__error" *ngIf="submetido && f['digito'].errors">
        <span>Campo obrigatório</span>
      </div>
    </div>
  </div>

  <div class="text-center">
    <p class="text-xs mb-4">Ao avançar você declara que concorda com os <a [href]="_urlService.contratoAdesao"
        target="_blank" rel="noopener noreferrer" class="txt-link">Termos de adesão</a>. </p>
  </div>

  <div class="text-center">
    <button>Continuar</button>
  </div>
</form>

<ftc-imagem *ngIf="openModalImage" [image]="_urlService.codigoOperacaoImagem" [text]="'Onde encontrar no cartão'"
  (fecharModal)="fecharModal()"></ftc-imagem>

