<div class="modal">
  <div class="modal-content p-8 bg-white w-[630px]">
    <div class="flex flex-row mb-6 justify-between">
      <div class="flex">
        <p class="txt-attention text-2xl mr-1" [class.txt-warning]="type === 10">Atenção</p>
        <ftc-icon-svg [icon]="type === 10 ? 'alerta' : 'attention'" [height]="24"></ftc-icon-svg>
      </div>

      <ftc-icon-svg [icon]="'closed'" class="cursor-pointer" (click)="modalfechar()"></ftc-icon-svg>
    </div>

    <div>
      <p *ngIf="type === 1" class="mb-4">
        Identificamos que você já é um cliente tagCAIXA, entre em
        contato com o número de telefone abaixo para concluir sua ativação.
      </p>

      <p *ngIf="type === 2" class="mb-4">
         Não foi possível concluir ativação online da sua tagCAIXA. Por favor conclua a ativação do seu plano pelo número abaixo e mencione o código 1002
      </p>

      <p *ngIf="type === 3" class="mb-4">
        Infelizmente não foi possível concluir a ativação da sua
        tagCAIXA. Para mais informações, entre em contato com a nossa central de
        atendimento e informe o código 1003.
      </p>

      <p *ngIf="type === 4" class="mb-4">
        Identificamos que seu CPF já possui um pedido de ativação em
        análise com esta placa, insira um veículo diferente ou entre em contato
        com o atendimento:
      </p>

      <p *ngIf="type === 5" class="mb-4">
        Esta tag já foi ativada. <br />Se a tag é nova, verifique possíveis
        erros de digitação e tente novamente.
      </p>

      <p *ngIf="type === 6" class="mb-4">
        Não conseguimos avançar com a ativação da sua tagCAIXA. Para continuar por favor ligue para:
      </p>

      <p *ngIf="type === 7" class="mb-4">Número de ativação incorreto. <br /> Verifique possíveis erros de digitação e
        tente
        novamente.
      </p>

      <p *ngIf="type === 8" class="mb-4">Dados da conta bancária inválidos. <br /> Verifique possíveis erros de digitação e
        tente
        novamente.
      </p>
      <p *ngIf="type === 9" class="mb-4">Nossos sistema está indisponivel no momento. Por favor, tente novamente em alguns instantes ou ligue para: </p>

      <p *ngIf="type === 10" class="mb-4">O CEP é inválido <br /> 
      </p>

      <p *ngIf="type === 11" class="mb-4">Não conseguimos concluir o cadastro do seu veículo. Por favor, tente novamente em alguns instantes ou ligue para: 
      </p>

      <p *ngIf="type === 12" class="mb-4">Não conseguimos concluir o cadastro do seu endereço. Por favor, tente novamente em alguns instantes ou ligue para: 
      </p>

      <p *ngIf="type === 13" class="mb-4">Estamos com problemas no sistema de pagamento. Por favor, tente novamente em alguns instantes ou ligue para: 
      </p>

      <p *ngIf="type === 14" class="mb-4">Tivemos um problem na atribuição da sua tag. Por favor, tente novamente em alguns instantes ou ligue para: 
      </p>
     
      <p *ngIf="type === 15" class="mb-4">A placa informada possui restrições ou problemas. Por favor, entre em contato com o atendimento e mencione o código 1005:
      </p>

      <p *ngIf="type === 16" class="mb-4">Infelizmente não foi possível concluir a ativação da sua
        tagCAIXA. Para mais informações, entre em contato com a nossa central de
        atendimento e informe o código 1006.  
      </p>

      <p *ngIf="type === 17" class="mb-4">Identificamos que seu CPF já possui um pedido de ativação em
        análise com esta placa, insira um veículo diferente ou entre em contato
        com o atendimento:  
      </p>

      <p>
        <span class="txt-primary"><a href="tel:30039590">3003 9590</a></span> (Capitais e Regiões
        Metropolitanas)
      </p>

      <p class="mb-4">
        <span class="txt-primary"><a href="tel:08007219590">0800 721 9590</a></span> (Demais Localidades)
      </p>

      <ftc-whatsapp-btn></ftc-whatsapp-btn>

      <P>Horário de Funcionamento das 09h às 18h de segunda feira à sexta feira e aos sabados das 9h as 15h.</P>
    </div>
  </div>
</div>