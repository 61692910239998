import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { AppAbstract } from 'src/app/app.abstract';
import { AtencaoComponent } from 'src/app/modals/atencao/atencao.component';
import { ImagemComponent } from 'src/app/modals/imagem/imagem.component';
import { ProgressoComponent } from 'src/app/modals/progresso/progresso.component';
import { DadosInterface } from 'src/app/models/dados.interface';

@Component({
  selector: 'ftc-ativar-dispositivo',
  templateUrl: './ativar-dispositivo.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    NgClass,
    NgIf,
    AtencaoComponent,
    ImagemComponent,
    ProgressoComponent
  ],
})
export class AtivarDispositivoComponent extends AppAbstract implements OnInit {
  protected readonly codigoOperacaoImagem: string = 'assets/images/instrucao_ativar_tag.png';

  protected dados: DadosInterface = {} as DadosInterface;

  protected openModalImage: boolean = false;
  protected openModalProgresso: boolean = false;
  protected submetido: boolean = false;

  protected modalAtencao: number = 0;

  protected formAtivar!: FormGroup;

  protected formaPagamentoSugerida: string[] = [];

  protected deviceDePara!: string;

  ngOnInit(): void {
    this.dados = this._localStorageService.descriptografarDados();
    this.criarFormulario();
  }

  private criarFormulario(): void {
    this.formAtivar = this._formBuilder.group({
      telefone: [this.dados.usuario?.telefone || '', this._clienteService.validarCelular],
      cpf: [this.dados?.usuario?.cpf || '', this._clienteService.validarCPF],
      dispositivo: [this.dados?.veiculo?.dispositivoCliente || '', this._deviceService.validarDispositivo],
    });
  }

  protected async ativarAgora(): Promise<void> {
    localStorage.clear();

    this.submetido = true;

    if (this.formAtivar.valid) {
      try {
        this.criptografarDadosIniciais();
      } catch (error) {
        this.openModalProgresso = false;
        this.modalAtencao = 6;
      }
    }
  }

  private criptografarDadosIniciais(): void {
    this.openModalProgresso = true;

    this.dados = {
      usuario: {
        cpf: this.formAtivar.value.cpf.replace(/\D/g, ''),
        telefone: this.formAtivar.value.telefone.replace(/\D/g, ''),
      },
      veiculo: {
        dispositivo: this.formAtivar.value.dispositivo.replace(/\D/g, '')
      }
    };

    this._localStorageService.criptografarDados(this.dados);
    this.postCreditoCpf();
  }

  private async postCreditoCpf(): Promise<void> {

    const cpf = this.formAtivar.value.cpf.replace(/\D/g, '');
    const payload = this._payloadService.payloadCreditoCpf(cpf);
    const resposta = await this._clienteService.postCreditoCpf(payload).toPromise();

    this._logService.setStorageUltimaApi('credito/cpf', resposta.status?.codigoRetorno, resposta.resultadoAnaliseCredito?.descricao);
    await this._logService.postLog();

    await this._ga4Service.ga4StartActivation(resposta.mensagem || resposta.errorMessage);

    const { resultadoAnaliseCredito, tipoCliente, formaPagamentoSugerida } = resposta;
    const { codigo, descricao } = resultadoAnaliseCredito;

    this.openModalProgresso = false;


    if (codigo === 'A' && tipoCliente === '2') {
      this.modalAtencao = 1;
    } else if (codigo === 'R' || descricao === 'Reprovado') {
      this.modalAtencao = 3;
    } else if (
      formaPagamentoSugerida.PREPAGO === 'NÃO' &&
      formaPagamentoSugerida.POSPAGO === 'NÃO'
    ) {   
      this.modalAtencao = 2;
    } else if ((codigo === 'A' || codigo === 'P') && ['1', '3', '4'].includes(tipoCliente)
    ) {
      this.getFormasPagamentoSugeridas(formaPagamentoSugerida);
    } else {
      this.modalAtencao = 6;
    }
  }

  private getFormasPagamentoSugeridas(formaPagamentoSugerida: any): void {
    this.openModalProgresso = true;

    const formas = [];

    if (formaPagamentoSugerida.PREPAGO === 'SIM') formas.push('PREPAGO');
    if (formaPagamentoSugerida.POSPAGO === 'SIM') formas.push('POSPAGO');

    this.formaPagamentoSugerida = formas;
    this.criptografarCreditoCpf();
  }

  private async criptografarCreditoCpf(): Promise<void> {
    this.dados = {
      ...this.dados,
      deviceFingerprintID: this._utilService.getId(),
      pagamento: {
        ...this.dados.pagamento,
        formasPagamentoSugerida: this.formaPagamentoSugerida,
      },
      usuario: {
        ...this.dados.usuario,
        nome: this.formAtivar.value.nome || '',
        email: this.formAtivar.value.email || ''
      }
    };

    this._localStorageService.criptografarDados(this.dados);
    this.postDispositivoObter();
  }

  private async postDispositivoObter(): Promise<void> {
    const dispositivo = this.formAtivar.value.dispositivo.replace(/\D/g, '');
    const payload = this._payloadService.payloadDispositivoObter(dispositivo);
    const resposta = await this._deviceService.postDispositivoObter(payload).toPromise();

    this._logService.setStorageUltimaApi('dispositivo/obter', resposta.status?.codigoRetorno, resposta.status?.mensagem);
    await this._logService.postLog();

    const { dados, status } = resposta;
    const codigoLoja = dados.dispositivo?.codigoLoja;
    const numeroIdentificador = dados.dispositivo?.numeroIdentificador;
    const mensagem = status?.mensagem;

    this.openModalProgresso = false;

    if (mensagem === 'Dispositivo não encontrado.' || mensagem === 'Código de ativação incorreto.') {
      this.modalAtencao = 7;
    } else if (mensagem === 'Sucesso' && codigoLoja === '3') {
      this.modalAtencao = 5;
    } else if (mensagem === 'Sucesso' && codigoLoja === '94') {
      this.criptografarDispositivoObter(numeroIdentificador);
    } else {
      this.modalAtencao = 6;
    }
  }

  private async criptografarDispositivoObter(numeroIdentificador: string): Promise<void> {
    this.dados.veiculo = {
      dispositivo: numeroIdentificador,
      dispositivoCliente: this.formAtivar.value.dispositivo.replace(/\D/g, '')
    };

    this._localStorageService.criptografarDados(this.dados);
    this._router.navigate(['/ativar']);
  }

  protected fecharModal(): void {
    this.modalAtencao = 0;
    this.openModalImage = false;
  }

  protected openOndeEcontroNumero(): void {
    this.openModalImage = true;
  }

  get f(): { [key: string]: any } {
    return this.formAtivar.controls;
  }

  get mascaraDispositivo(): string {
    const prefix = this.formAtivar.value.dispositivo.slice(0, 4);
    if (prefix === '07') return '00000000000';
    if (prefix === '1049') return '000000000000000';
    return '00000000000';
  }
}
